import React from 'react';
import apps_config_map from '../appsConfig';
import { useLocation } from 'react-router-dom';

const Disclaimer = () => {
  const location = useLocation();
  const app = location.pathname.split('/')[1];
  return (
    <div className="bmc-disclaimer mb-5">
      <strong>DISCLAIMER</strong> – This website is not in any way affiliated with, maintained, endorsed, or sponsored
      by {apps_config_map[app].company}, Inc. or its affiliates. This is an independent, unofficial site that uses{' '}
      {apps_config_map[app].displayName} documentation made publicly available by {apps_config_map[app].company} and is
      Copyright © {apps_config_map[app].company}, Inc. {apps_config_map[app].company} reserves all rights to the{' '}
      {apps_config_map[app].displayName} documentation. {apps_config_map[app].company}, the{' '}
      {apps_config_map[app].company} logo, {apps_config_map[app].displayName}, and other {apps_config_map[app].company}{' '}
      marks are assets of {apps_config_map[app].company}, Inc. These trademarks are registered and may be registered in
      the U.S. and in other countries. <br />
      <span style={{ fontWeight: 'bold' }}>
        This chat app, Okami, can make mistakes. Consider checking important information.
      </span>
    </div>
  );
};

export default Disclaimer;
