import React, { useContext, useState, useCallback } from 'react';
import { Button, Col, Row, Modal, Form } from 'react-bootstrap';
import { sendFeedback } from '../api/SendMessagesHelper';
import { ChatHistoryContext } from './HistoryContext';
import { debounce } from '../utils/debounce';

const FeedbackButtons = ({ user, responseIndex }) => {
  const [feedback, setFeedback] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [comments, setComments] = useState('');
  const { selectedChat } = useContext(ChatHistoryContext);

  const handleFeedback = useCallback(async (newFeedback, comments = '') => {
    setFeedback(newFeedback);
    try {
      await sendFeedback(selectedChat.chatHistory, newFeedback, user.email, responseIndex, comments);
    } catch (error) {
      console.error('Error on sending feedback: ', error);
    }
  }, [selectedChat, user?.email, responseIndex]);


  const debouncedHandleFeedback = useCallback(debounce(handleFeedback, 500), [handleFeedback]);

  const handleThumbsDown = () => {
    setShowModal(true);
    setFeedback(false);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalSubmit = async () => {
    setShowModal(false);
    await debouncedHandleFeedback(false, comments);
  };

  if (!user || !user.email) {
    return null;
  }

  return (
      <>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <div className="align-middle mb-4 mx-4">
              <Button
                  variant={feedback === true ? "success" : "outline-success"}
                  className={`custom-feedback-button ${feedback === true ? 'btn-success' : 'btn-outline-success'}`}
                  onClick={() => debouncedHandleFeedback(true)}
                  size="sm"
              >
                <i className="bi bi-hand-thumbs-up"></i>
              </Button>
              &nbsp;
              <Button
                  variant={feedback === false ? "danger" : "outline-danger"}
                  className={`custom-feedback-button ${feedback === false ? 'btn-danger' : 'btn-outline-danger'}`}
                  onClick={handleThumbsDown}
                  size="sm"
              >
                <i className="bi bi-hand-thumbs-down"></i>
              </Button>
            </div>
          </Col>
        </Row>
        <Modal show={showModal} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Help us improve and share some comments!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="comments">
                <Form.Label>Comments</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={3}
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleModalSubmit}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </>
  );
};

export default FeedbackButtons;
