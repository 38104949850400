import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { Auth0Provider } from '@auth0/auth0-react'
import Hotjar from '@hotjar/browser'
import TagManager from 'react-gtm-module'
import { BrowserRouter as Router, Routes, Route, Navigate, Link } from 'react-router-dom'

const tagManagerArgs = {
	gtmId: 'GTM-T96454KW',
}

TagManager.initialize(tagManagerArgs)

const initializeAnalytics = () => {
	window.dataLayer = window.dataLayer || []
	function gtag() {
		window.dataLayer.push(arguments)
	}
	gtag('js', new Date())
	gtag('config', 'G-FLZG9LRM59')
}

const root = ReactDOM.createRoot(document.getElementById('root'))
const siteId = 3648910
const hotjarVersion = 6

// Initialize Hotjar and Google Analytics only in production
if (process.env.NODE_ENV === 'production') {
	Hotjar.init(siteId, hotjarVersion)
	initializeAnalytics()
}

const providerConfig = {
	domain: process.env.REACT_APP_AUTH0_DOMAIN,
	clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
	cacheLocation: "localstorage",
	authorizationParams: {
		redirect_uri: window.location.origin,
		// ...(config.audience ? { audience: config.audience } : null),
	},
}

root.render(
	<Router>
		<Auth0Provider {...providerConfig}>
			<App/>
			{/* <Routes>
				<Route path="/" element={<App />} />
				<Route path="*" element={<Navigate to="/" />} />
			</Routes>
			</Routes> */}
		</Auth0Provider>
	</Router>
)
