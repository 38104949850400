import React, { useContext, useDeferredValue, useEffect, useState } from 'react';
import BotReplyMessage from './messages/BotReplyMessage';
import UserReplyMessage from './messages/UserReplyMessage';
import { ChatHistoryContext } from './HistoryContext';
import FeedbackButtons from './FeedbackButtons';

const ChatResponse = ({ user, responseMessage, isWaitingForResponse }) => {
  const [activeTab, setActiveTab] = useState('response');
  const { selectedChat } = useContext(ChatHistoryContext);
  const chatHistory = selectedChat.history.filter((item) => !!item);

  useEffect(() => {
    if (selectedChat.dataRefs) {
      setActiveTab('response');
    }
  }, [selectedChat.dataRefs]);

  const isWaitingDeferred = useDeferredValue(isWaitingForResponse);

  const getDataRefByUrl = (url) => {
    if (!selectedChat) {
      console.error('selectedChat is undefined');
      return null;
    }

    if (!selectedChat.dataRefs || selectedChat.dataRefs.length !== selectedChat.dataRefs.filter(ref => !!ref).length) {
      return null;
    }

    const dataRef = selectedChat.dataRefs.find(ref => ref.url === url);
    return dataRef ? dataRef : null;
  };

  return (
      <>
        <div className="message-inner-content">
          <div></div>
          <div className="msgColumn">
            {selectedChat.title && <UserReplyMessage message={selectedChat.title} userImage={user?.img} />}
            {chatHistory.map((item, index) =>
                item.content === '' ? null : item.role === 'user' ? (
                    <UserReplyMessage key={index} message={item.content} userImage={user?.img} />
                ) : (
                    <div key={index}>
                      <BotReplyMessage key={index} message={item.content} getDataRefByUrl={getDataRefByUrl} />
                      <FeedbackButtons user={user || {}} responseIndex={index} />
                    </div>
                ),
            )}
            {isWaitingDeferred && (
                <div>
                  <BotReplyMessage
                      message={responseMessage.message}
                      isWaiting={isWaitingForResponse}
                      getDataRefByUrl={getDataRefByUrl}
                  />
                </div>
            )}
          </div>
          <div></div>
        </div>
      </>
  );
};

export default ChatResponse;
