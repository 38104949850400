import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

const ScrollToBottomButton = ({ onClick, visible }) => {
    if (!visible) return null;

    return (
        <button
            onClick={onClick}
            className='scroll-to-bottom-btn'
            aria-label='Scroll to bottom'
        >
            <FontAwesomeIcon icon={faArrowDown} size={"lg"} />
        </button>
    );
};

export default ScrollToBottomButton;
