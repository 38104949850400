import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { AuthenticationStatus } from '../App';
import { sendVerificationEmail } from '../api/SendMessagesHelper';
import StartChatInput from './StartChatInput';

const GettingStarted = ({
  trialMessagesRemaining,
  appConfig,
  appEnabled,
  authenticationstatus,
  showInstructions,
  startChatInputProps,
}) => {
  const { loginWithRedirect, user, isAuthenticated } = useAuth0();

  const resend_verification_email = async () => {
    if (user && !user.email_verified) {
      await sendVerificationEmail(user.sub);
    }
  };

  return (
    <div className="pb-2">
      <Container>
        <Row className="justify-content-center mb-4">
          <Col className="text-center">
            <br />
            <br />
            <h1>
              <b className="okami-title">
                Ō<span className="k-letter">k</span>ami
              </b>
            </h1>
            {!appEnabled && <p className="slogan">seek how? know now!</p>}
          </Col>
          {(appEnabled || trialMessagesRemaining <= 0) && <StartChatInput {...startChatInputProps} />}

          {!appEnabled && authenticationstatus === AuthenticationStatus.AUTHENTICATED_NOT_CONFIRMED && (
            <h4 className="auth-extra-msg">
              A confirmation mail has been sent (if you face any issue drop us a mail at support@okami.io){' '}
              <span>{user.email}</span>
              <br />
              <br />
              <a
                href="/"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Refresh after confirming your email
              </a>
              <br />
              <br />
              <Button variant="primary" onClick={resend_verification_email}>
                Resend
              </Button>
            </h4>
          )}
          {(!appEnabled && authenticationstatus === !AuthenticationStatus.AUTHENTICATED_NOT_CONFIRMED) ||
            (authenticationstatus === AuthenticationStatus.NOT_AUTHENTICATED && (
              <Row>
                <Col xs={3} md={3}></Col>
                <Col xs={6} md={6} className="text-center">
                  <div className="login-buttons d-flex justify-content-center">
                    <Button
                      variant="primary"
                      onClick={() =>
                        loginWithRedirect({
                          authorizationParams: {
                            screen_hint: 'signup',
                          },
                        })
                      }
                      className="login-btn"
                    >
                      <b>Free signup</b>
                    </Button>
                    &nbsp;
                    <Button
                      className="login-btn"
                      variant="secondary"
                      onClick={() => loginWithRedirect({ action: 'login' })}
                    >
                      <b>Login</b>
                    </Button>
                  </div>
                </Col>
                <Col xs={3} md={3}></Col>
              </Row>
            ))}
          {(!appEnabled || trialMessagesRemaining > 0) && (
            <Row>
              <Col xs={0} md={2} />
              <Col xs={12} md={8}>
                <p className="mission mt-5">
                  Okami's mission is to empower developers, DevOps professionals, QA teams, and others with AI-driven
                  chat capabilities for instant access to documentation. We provide precise answers for various
                  applications and products, including AWS, GitHub, Jenkins, Docker, and more. Our service enables users
                  to explore product functionalities and troubleshoot issues effectively.
                  <br />
                </p>
                <Col xs={0} md={2} />
              </Col>
              <Row></Row>
            </Row>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default GettingStarted;
