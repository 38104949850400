import React, { createContext, useState, useEffect } from 'react';

export const ChatHistoryContext = createContext(null);

const MAX_HISTORY_LENGTH = 10;

const HistoryContext = ({ children }) => {
  const [history, setHistory] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);

  useEffect(() => {
    const storedHistory = JSON.parse(window.localStorage.getItem('history'));
    if (storedHistory) {
      setHistory(storedHistory);
    }
  }, []);

  function addToHistory(msg, chatId, app) {
    const newChat = { chatId: chatId, id: 0, title: msg, dataRefs: [], history: [], app, isGenerating: true };
    setSelectedChat(newChat);
    let newHistory = [...history];
    newHistory.forEach((obj) => obj.id++);
    newHistory = newHistory.sort((item, item2) => item.id - item2.id);
    newHistory.unshift(newChat);

    if (newHistory.length > 15) {
      newHistory.pop();
    }
    setHistory(newHistory);
    window.localStorage.setItem('history', JSON.stringify(newHistory));
  }

  function updateChat(chatId, newMsg, dataRefs) {
    const storedHistory = JSON.parse(window.localStorage.getItem('history'));
    const chat = storedHistory.find((el) => el.chatId === chatId);
    const updatedChat = {
      ...chat,
      history: newMsg ? [...chat.history, newMsg] : chat.history,
      dataRefs: dataRefs || chat.dataRefs,
    };
    let updatedHistory = storedHistory.map((item) => (item.chatId === chatId ? updatedChat : item));
    setSelectedChat(updatedChat);

    setHistory(updatedHistory);
    window.localStorage.setItem('history', JSON.stringify(updatedHistory));
  }

  const clearChatHistory = (chatId) => {
    window.location.reload();
  };

  const stopResponseGeneration = (chatId) => {
    const storedHistory = JSON.parse(window.localStorage.getItem('history'));
    const chat = storedHistory.find((el) => el.chatId === chatId);
    if (chat) {
      chat.isGenerating = false;
      const updatedHistory = storedHistory.map((item) => (item.chatId === chatId ? chat : item));
      setHistory(updatedHistory);
      window.localStorage.setItem('history', JSON.stringify(updatedHistory));
      setSelectedChat(chat);
    }
  };

  return (
      <ChatHistoryContext.Provider
          value={{
            history,
            addToHistory,
            updateChat,
            selectedChat,
            setSelectedChat,
            clearChatHistory,
            stopResponseGeneration
          }}
      >
        {children}
      </ChatHistoryContext.Provider>
  );
};

export default HistoryContext;
