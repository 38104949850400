import React from 'react'
import { FaUserCircle } from 'react-icons/fa'
import okamipaw from '../images/okamipaw.png'

export const PersonAvatar = ({ src }) => (
	<div className="d-flex justify-content-center align-content-center">
		{src ? (
			<img alt="avatar" src={src} className='avatar' style={{ width: "20px", height: "20px" }} />
		) : (
			<FaUserCircle className="avatar-icon" />
		)}
	</div>
)

export const ComputerAvatar = (props) => (
	<div style={{ width: "fit-content" }}>
		<img src={okamipaw} alt="Computer Avatar" className="avatar-icon" />
	</div>
)
