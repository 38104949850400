import React, { useContext, useEffect, useRef, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useLocalStorageBoolean } from '../api/useLocalStorageBoolean';
import GettingStarted from './GettingStarted';
import PromptCompletionPair from './PromptCompletionPair';
import { ChatHistoryContext } from './HistoryContext';
import StartChatInput from './StartChatInput';
import Disclaimer from './Disclaimer';
import { v4 as uuidv4 } from 'uuid';
import { createNewChat, getDataRefReq, replyChat } from '../api/SendMessagesHelper';
import ScrollToBottomButton from './ScrollToBottomButton';
import socket from '../api/socket';

const Chat = ({
  app,
  appEnabled,
  user,
  authenticationstatus,
  activeProduct,
  loginWithRedirect,
  isAuthenticated,
  trialMessagesRemaining,
  setTrialMessagesRemaining,
  setAppEnabled,
}) => {
  const [startTime, setStartTime] = useState(null);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const { selectedChat, addToHistory, updateChat, stopResponseGeneration } = useContext(ChatHistoryContext);
  const wrapperContentRef = useRef(null);
  const contentRef = useRef(null);
  const [isGpt4Enabled] = useLocalStorageBoolean('isGpt4Enabled', false);
  const [isAutoScroll, setIsAutoScroll] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [isStopDisabled, setIsStopDisabled] = useState(false);

  useEffect(() => {
    if (isWaitingForResponse) {
      setStartTime(new Date());
    } else if (startTime) {
      setStartTime(null);
    }
  }, [isWaitingForResponse]);

  const handleContentResize = (entries) => {
    if (!wrapperContentRef.current) return;
    const entry = entries[0];
    const messages = wrapperContentRef.current.querySelectorAll('.promptCompletionPair');
    const lastMessageHeight = messages[messages.length - 1]?.clientHeight;
    if (!lastMessageHeight) return;
    if (isAutoScroll) {
      wrapperContentRef.current.scrollTop = entry.target?.clientHeight;
    }
  };

  useEffect(() => {
    const observer = new ResizeObserver(handleContentResize);
    if (contentRef.current) {
      observer.observe(contentRef.current);
    }
    return () => {
      if (contentRef.current) {
        observer.unobserve(contentRef.current);
      }
    };
  }, [contentRef.current, isAutoScroll]);

  useEffect(() => {
    const handleScroll = () => {
      if (!wrapperContentRef.current) return;
      const { scrollTop, scrollHeight, clientHeight } = wrapperContentRef.current;
      if (scrollHeight - scrollTop <= clientHeight + 50) {
        setIsAutoScroll(true);
        setShowScrollButton(false);
      } else {
        setIsAutoScroll(false);
        setShowScrollButton(true);
      }
    };

    if (wrapperContentRef.current) {
      wrapperContentRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (wrapperContentRef.current) {
        wrapperContentRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (isAutoScroll && wrapperContentRef.current) {
      wrapperContentRef.current.scrollTop = wrapperContentRef.current.scrollHeight;
    }
  }, [selectedChat]);

  const handleMessageSubmit = (msg) => {
    if (!msg) return;

    if (!isAuthenticated && trialMessagesRemaining <= 0) {
      setAppEnabled(false);
      return;
    }

    setIsStopDisabled(true);
    const messageUUID = uuidv4();
    addToHistory(msg, messageUUID, app);
    setIsWaitingForResponse(true);
    const userEmail = user?.email || 'guest@example.com';
    getDataRefReq(msg, app, messageUUID, [{ role: 'user', content: msg }], isGpt4Enabled, userEmail).then((data) => {
      updateChat(messageUUID, null, data.data_refs);
      createNewChat(msg, data.data_refs, data.okami_chat, app, messageUUID, isGpt4Enabled, userEmail);
      handleScrollToBottom();
      if (!isAuthenticated) {
        setTrialMessagesRemaining((prev) => prev - 1);
        if (trialMessagesRemaining - 1 <= 0) {
          setAppEnabled(false);
        }
      }
    });
  };

  const handleReplySubmit = (msg) => {
    if (!selectedChat) {
      console.error('No selected chat');
      return;
    }

    if (!isAuthenticated && trialMessagesRemaining <= 0) {
      setAppEnabled(false);
      return;
    }

    setIsStopDisabled(true);
    setIsWaitingForResponse(true);
    const userEmail = user?.email || 'guest@example.com';
    updateChat(selectedChat.chatId, { role: 'user', content: msg }, null);
    replyChat(msg, selectedChat.dataRefs, app, selectedChat.chatId, isGpt4Enabled, userEmail, selectedChat.history);
    handleScrollToBottom();
    if (!isAuthenticated) {
      setTrialMessagesRemaining((prev) => prev - 1);
      if (trialMessagesRemaining - 1 <= 0) {
        setAppEnabled(false);
      }
    }
  };

  const handleScrollToBottom = () => {
    if (wrapperContentRef.current) {
      wrapperContentRef.current.scrollTop = wrapperContentRef.current.scrollHeight;
      setIsAutoScroll(true);
    }
  };

  const handleStopResponse = () => {
    if (selectedChat) {
      stopResponseGeneration(selectedChat.chatId);
      setIsWaitingForResponse(false);
      setIsStopDisabled(false);
      socket.off(selectedChat.chatId);
    }
  };

  const startChatInputProps = {
    authenticationstatus,
    appEnabled,
    onSubmit: selectedChat ? handleReplySubmit : handleMessageSubmit,
    isWaitingForResponse,
    setIsWaitingForResponse,
    handleStopResponse,
    trialMessagesRemaining,
    isAuthenticated,
    loginWithRedirect,
  };

  return (
    <>
      <div className="content-container" ref={wrapperContentRef}>
        <div style={{ overflow: 'hidden' }} ref={contentRef}>
          {!selectedChat && (
            <GettingStarted
              trialMessagesRemaining={trialMessagesRemaining}
              appConfig={app}
              appEnabled={appEnabled}
              authenticationstatus={authenticationstatus}
              showInstructions={true}
              startChatInputProps={startChatInputProps}
            />
          )}
          {appEnabled && <Disclaimer />}
          <Row>
            {selectedChat && (
              <PromptCompletionPair
                user={user}
                isWaitingForResponse={isWaitingForResponse}
                setIsWaitingForResponse={setIsWaitingForResponse}
                onReply={handleReplySubmit}
                setIsStopDisabled={setIsStopDisabled}
                isStopDisabled={isStopDisabled}
              />
            )}
          </Row>
        </div>
      </div>
      {selectedChat && (
        <div>
          <StartChatInput {...startChatInputProps} />
          <ScrollToBottomButton onClick={handleScrollToBottom} visible={showScrollButton} />
        </div>
      )}
    </>
  );
};

export default Chat;
