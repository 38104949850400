import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Card, Col, Row} from 'react-bootstrap';
import {apps_config_list} from '../appsConfig';
import GettingStarted from './GettingStarted';

function HomePage() {
  const navigate = useNavigate();

    const visibleApps = apps_config_list.filter(app => !app.invisible);


    return (
    <div className="pb-2 px-5 overflow-y-auto">
     <GettingStarted
              showInstructions={false}

        />
        <p className="Choose-one-get-started">Choose one to get started:</p>
        <Row >
          
        {visibleApps.map(app => (
          <Col md={4} sm={6} key={app.id} className="mb-3">
            <Card className="h-100 text-center p-2 tile" onClick={() => navigate(`/${app.id}`)}>
              <Card.Body>
                <Card.Title>{app.displayName}</Card.Title>
                <Card.Text>{app.company}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default HomePage;
