import React, {useContext} from 'react';
import {FaBars} from 'react-icons/fa';
import LogOutButton from './LogoutButton';
import Navbar from 'react-bootstrap/Navbar';
import {Button} from 'react-bootstrap';
import {useLocation} from 'react-router-dom';
import NavbarDropdown from "./NavbarDropdown";
import appsConfig from "../appsConfig";
import {ChatHistoryContext} from "./HistoryContext";

function NavbarComponent({ appEnabled, user, isAuthenticated, toggle, showSettingsModal }) {
  const app = appsConfig;
  const location = useLocation();
  const { setSelectedChat } = useContext(ChatHistoryContext);

  const activeProductKey = location.pathname.split('/')[1];
  const activeProduct = app[activeProductKey]?.displayName || '';

  const resetSelectedChat = () => {
    setSelectedChat(null);
  };

  return (
      <Navbar className="navTop px-0" style={{ zIndex: 500 }}>
        <div className="navbar-flex-container">
        {isAuthenticated && (
          <Button onClick={toggle} style={{border: 'none', background: 'none' }}>
            <FaBars style={{ color: '#004AAD' }} />
          </Button>
        )}
        <span style={{ marginRight: '20px', border: 'none', background: 'none' }}>  </span>
          <NavbarDropdown
              app={app}
              activeProduct={activeProduct}
              resetSelectedChat={resetSelectedChat}
          />
          {appEnabled && isAuthenticated && (
              <div className="ms-auto d-flex align-items-center gap-2">
                <img
                    alt="avatar"
                    src={user?.picture}
                    className="avatar nav-avatar"
                    onClick={() => showSettingsModal(true)}
                />
                <div className="LogOutButton">
                  <LogOutButton />
                </div>
              </div>
          )}
        </div>
      </Navbar>
  );
}

export default NavbarComponent;
