import apps_config_map from './appsConfig'; // Adjust the path as necessary
import React, { createContext, useEffect, useState } from 'react';
import Chat from './components/Chat';
import './styles/styles.css';
import { useAuth0 } from '@auth0/auth0-react';
import Modal from 'react-bootstrap/Modal';
import { sendVerificationEmail } from './api/SendMessagesHelper';
import SideBar from './components/CustomSideBar/SideBar';
import NavbarComponent from './components/Navbar';
import AccountSettingsModal from './components/Modals/AccountSettingsModal';
import { Button } from 'react-bootstrap';
import { Routes, Route, Navigate, useSearchParams } from 'react-router-dom';
import HistoryContext from './components/HistoryContext';
import HomePage from './components/HomePage';
import './styles/dracula.css';
import { useLocalStorageInteger } from './api/useLocalStorageBoolean';

export const AuthenticationStatus = {
  NOT_AUTHENTICATED: 'not_authenticatedֿ',
  AUTHENTICATED_NOT_CONFIRMED: 'authenticated_not_confirmed',
  AUTHENTICATED_CONFIRMED_NO_MESSAGE_LEFT: 'authenticated_confirmed_no_message_left',
  AUTHENTICATED_CONFIRMED_MESSAGE_LEFT: 'authenticated_confirmed_message_left',
};

function App() {
  const { loginWithRedirect, user, isAuthenticated, isLoading } = useAuth0();
  const [showInAppBrowserModal, setShowInAppBrowserModal] = useState(false);
  const [activeProduct, setActiveProduct] = useState(() => {
    return Object.keys(apps_config_map)?.find((key) => key === 'aws');
  });

  const [appEnabled, setAppEnabled] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  // Determine initial sidebar state based on screen width
  const initialIsMobile = window.innerWidth <= 768;
  const [isSideBarOpen, setIsSideBarOpen] = useState(!initialIsMobile);

  const toggleSidebar = () => {
    setIsSideBarOpen(!isSideBarOpen);
  };

  const closeSidebar = () => {
    setIsSideBarOpen(false);
  };

  useEffect(() => {
    if (searchParams.get('iss') === 'https://auth.okami.io/') {
      const urlParams = new URLSearchParams(window.location.search);
      localStorage.clear();
      urlParams.delete('iss');
      setSearchParams(urlParams);
    }
  }, [searchParams]);

  const detectInAppBrowser = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/LinkedIn/i.test(userAgent) || /FB_IAB/i.test(userAgent) || /Instagram/i.test(userAgent)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (detectInAppBrowser()) {
      setShowInAppBrowserModal(true);
    }
  }, []);

  const updateWidth = () => {
    const width = window.innerWidth;
    const widthLimit = 768;
    const currentIsMobile = width <= widthLimit;

    if (currentIsMobile) {
      setIsSideBarOpen(false);
    } else {
      setIsSideBarOpen(true);
    }
  };

  useEffect(() => {
    const handleResize = () => updateWidth();
    window.addEventListener('resize', handleResize);

    updateWidth();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const resend_verification_email = async () => {
    if (user && !user.email_verified) {
      await sendVerificationEmail(user.sub);
    }
  };

  const [messagesLeft, setMessagesLeft] = useLocalStorageInteger('trialMessagesRemaining', 5);
  const [accountSettingsModalShow, setAccountSettingsModalShow] = useState(false);
  const [authenticationstatus, setAuthenticationStatus] = useState(AuthenticationStatus.NOT_AUTHENTICATED);
  const [curUser, setCurUser] = useState({ email: 'none' });
  const [appTitle, setAppTitle] = useState('');

  const toggle = () => setIsSideBarOpen((prev) => !prev);

  useEffect(() => {
    let status;
    const isUserVerified = user?.email_verified;
    const isMessagesLeft = messagesLeft > 0;

    if (!isAuthenticated) {
      status = AuthenticationStatus.NOT_AUTHENTICATED;
      setAppEnabled(isMessagesLeft);
    } else {
      if (!isUserVerified) {
        status = AuthenticationStatus.AUTHENTICATED_NOT_CONFIRMED;

        function getQueryParam(param) {
          const urlParams = new URLSearchParams(window.location.search);
          return urlParams.get(param);
        }

        const issParam = getQueryParam("iss");
        const urlParams = new URLSearchParams(window.location.search);
        if (issParam === "https://auth.okami.io/") {
          urlParams.delete("iss");
          setSearchParams(urlParams);
          localStorage.clear();
        }
      } else {
        status = AuthenticationStatus.AUTHENTICATED_CONFIRMED_MESSAGE_LEFT;
        setCurUser(user);
        setAppEnabled(true);
      }

      setAuthenticationStatus(status);
    }
  }, [isAuthenticated, user, messagesLeft]);

  useEffect(() => {
    if (isAuthenticated) {
      localStorage.removeItem('trialMessagesRemaining')
      setAppEnabled(true);
    }
  }, [isAuthenticated]);

  return (
      <HistoryContext>
        <div className="wrapper position-relative">
          <div className="d-flex mh-100 flex-column" style={{ height: '100dvh' }}>
            <NavbarComponent
                showSettingsModal={setAccountSettingsModalShow}
                appEnabled={appEnabled}
                appTitle={appTitle}
                user={user}
                isAuthenticated={isAuthenticated}
                toggle={toggle}
            />
            <div className="d-flex w-100 h-100 overflow-hidden position-relative">
              {!!isAuthenticated && !!!isLoading && !!user && (
                  <AccountSettingsModal
                      show={accountSettingsModalShow}
                      hide={setAccountSettingsModalShow}
                      user={user}
                      // messagesLeft={messagesLeft}
                  />
              )}
              {isAuthenticated && (
                  <SideBar
                      isOpen={isSideBarOpen}
                      app={apps_config_map}
                      user={user}
                      isAuthenticated={isAuthenticated}
                      appEnabled={appEnabled}
                      activeProduct={activeProduct}
                      setActiveProduct={setActiveProduct}
                      closeSidebar={closeSidebar}
                      toggleSidebar={toggleSidebar}
                  />
              )}
              {isLoading ? (
                  <LoadingModal />
              ) : (
                  <div className="w-100 h-100 d-flex flex-column overflow-hidden position-relative">
                    <Routes>
                      <Route path="/home" element={<HomePage />} />
                      {Object.entries(apps_config_map).map(([key, config]) => (
                          <Route
                              key={key}
                              path={`/${key}`}
                              element={
                                <Chat
                                    key={key}
                                    app={key}
                                    setAppTitle={setAppTitle}
                                    appConfig={config}
                                    setActiveProduct={setActiveProduct}
                                    activeProduct={key}
                                    appEnabled={appEnabled}
                                    user={user}
                                    authenticationstatus={authenticationstatus}
                                    loginWithRedirect={loginWithRedirect}
                                    setTrialMessagesRemaining={setMessagesLeft}
                                    trialMessagesRemaining={messagesLeft}
                                    isAuthenticated={isAuthenticated}
                                    setAppEnabled={setAppEnabled}
                                />
                              }
                          />
                      ))}
                      <Route path="/" element={<Navigate to="/home" />} />
                      <Route path="*" element={<Navigate to="/home" />} />
                    </Routes>
                  </div>
              )}
            </div>
          </div>
          <Modal show={showInAppBrowserModal} onHide={() => setShowInAppBrowserModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Open in Browser</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <strong>Please use Chrome or Safari to access https://okami.io/</strong>
              <br />
              Google blocks signups through links opened within apps, like LinkedIn or Facebook. To sign up, please open
              https://okami.io in Chrome or Safari.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowInAppBrowserModal(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </HistoryContext>
  );
}

function LoadingModal() {
  return (
      <Modal show={true} centered className="w-100 h-100">
        <Modal.Header
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
        >
          {/* <Modal.Title>Loading...</Modal.Title> */}
          <video autoPlay loop muted width="250">
            <source src="/videos/Okami_L_2.mp4" type="video/mp4" />
            Download the
            <a href="/media/cc0-videos/flower.webm">WEBM</a>
            or
            <a href="/media/cc0-videos/flower.mp4">MP4</a>
            video.
          </video>
        </Modal.Header>
      </Modal>
  );
}

export default App;
