import React, { useState, useEffect } from 'react'

const Dots = (props) => {
    const [dots, setDots] = useState('')
    const [waitingMessage, setWaitingMessage] = useState('Searching 🔍 ')
    const loading = props.isWaitingForResponse

    useEffect(() => {
        if (loading) {
            const messageTimeout = setTimeout(() => {
                setWaitingMessage('Thinking 💭 ')
            }, 4000)

            const longWaitTimeout = setTimeout(() => {
                setWaitingMessage('This is awkward 🤪 it\'s taking a long time. If you posted a long question, it makes sense. If not, I\'m sorry. Please refresh the page.')
            }, 25000)

            const dotsInterval = setInterval(() => {
                setDots((prevDots) => {
                    if (prevDots.length === 10) {
                        return '.'
                    }
                    return prevDots + '.'
                })
            }, 300)

            return () => {
                clearTimeout(messageTimeout)
                clearTimeout(longWaitTimeout)
                clearInterval(dotsInterval)
            }
        }
    }, [loading])

    return (
        <span>
            <span style={{fontSize: '18px'}}>{waitingMessage}</span>
            {loading ? dots : ''}
        </span>
    )
}

export default Dots
