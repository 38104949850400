import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppSelect } from './AppSelect';
import { Button, FormControl, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaintRoller, faPlay, faStop } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChatHistoryContext } from './HistoryContext';
import apps_config_map from '../appsConfig';

const popover = (data) => {
  return (
    <Popover id="popover-basic">
      <Popover.Body>{data}</Popover.Body>
    </Popover>
  );
};

const StartChatInput = ({
  appEnabled,
  onSubmit,
  isWaitingForResponse,
  handleStopResponse,
  trialMessagesRemaining,
  isAuthenticated,
  loginWithRedirect,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { clearChatHistory, selectedChat } = useContext(ChatHistoryContext);

  const selectedApp = location.pathname.split('/')[1];
  const displayChatName = apps_config_map[selectedApp]?.displayName || 'Unknown App';

  const inputRef = useRef();

  const [isDataSourceListOpen, setIsDataSourceListOpen] = useState(false);
  const [msgInput, setMsgInput] = useState('');

  useEffect(() => {
    setTimeout(() => inputRef.current?.focus(), 100);
  }, [location.pathname]);

  const handleMessageChange = (e) => {
    const value = e.target.value;
    setMsgInput(value);

    if (value === '@') {
      setIsDataSourceListOpen(true);
    } else {
      setIsDataSourceListOpen(false);
    }

    adjustTextareaHeight(e.target);
  };

  const adjustTextareaHeight = (element) => {
    element.style.height = 'auto';
    const lines = element.value.split('\n').length;
    const charLimit = window.innerWidth < 576 ? 25 : 50;
    if (lines > 1 || element.value.length > charLimit) {
      element.style.height = `${element.scrollHeight}px`;
    } else {
      element.style.height = '38px';
    }
  };

  const handleAppChange = (app) => {
    navigate(`/${app}`);
    setMsgInput('');
    resetInputHeight();
  };

  const handleSubmit = () => {
    if (msgInput === '') {
      resetInputHeight();
      return;
    }
    onSubmit(msgInput);
    setMsgInput('');
    resetInputHeight();
  };

  const handleClearHistory = () => {
    if (selectedChat?.chatId) {
      clearChatHistory(selectedChat.chatId);
    }
  };

  const resetInputHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = '38px';
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-center w-100">
      <div className="w-100 pt-2 rounded px-2 chat-form">
        <div className="w-100 p-2 pb-4 rounded">
          <AppSelect
            isOpen={isDataSourceListOpen}
            setIsOpen={setIsDataSourceListOpen}
            setActiveProduct={handleAppChange}
            clearInput={() => setMsgInput('')}
          />
          <div style={{ marginBottom: '5px' }}>
            Ask me about <span className="first-letter-uppercase">{displayChatName}</span>
            <span className="this-is-beta"> - (Okami may make mistakes)</span>
          </div>
          <div className="d-flex w-100 gap-2">
            <div className="w-100 position-relative">
              <label className="custom-placeholder">
                {!msgInput && (
                  <span className="placeholder-input">How can I assist you today?</span>
                )}
              </label>
              <FormControl
                ref={inputRef}
                as="textarea"
                className="input-control d-flex align-items-center"
                value={msgInput}
                onChange={handleMessageChange}
                disabled={!appEnabled || isWaitingForResponse}
                style={{ resize: 'none', overflowY: 'auto', height: '38px' }}
                onKeyDown={(event) => {
                  if (event.keyCode === 13 && event.shiftKey) {
                    setMsgInput((prev) => prev + '');
                  } else if (event.keyCode === 13) {
                    event.preventDefault();
                    handleSubmit();
                  }
                }}
              />
            </div>
            {isWaitingForResponse ? (
              <OverlayTrigger rootClose={true} overlay={popover('Stop')}>
                <Button
                  variant="danger"
                  className="stop-button cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    handleStopResponse();
                  }}
                >
                  <FontAwesomeIcon icon={faStop} />
                </Button>
              </OverlayTrigger>
            ) : (
              <OverlayTrigger rootClose={true} overlay={popover('Play')}>
                <Button
                  disabled={!appEnabled || trialMessagesRemaining <= 0}
                  type="submit"
                  className="send-button cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <FontAwesomeIcon icon={faPlay} />
                </Button>
              </OverlayTrigger>
            )}
            {selectedChat && (
              <OverlayTrigger rootClose={true} overlay={popover('Start Over')}>
                <Button className="newchat-button cursor-pointer" onClick={handleClearHistory}>
                  <FontAwesomeIcon icon={faPaintRoller} />
                </Button>
              </OverlayTrigger>
            )}
          </div>
          {!isAuthenticated && (
            <div className="d-flex align-items-center justify-content-evenly">
              <div>
                {trialMessagesRemaining} messages left &nbsp;
                {!isAuthenticated && trialMessagesRemaining <= 0 && (
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent the default link behavior
                      loginWithRedirect({
                        authorizationParams: {
                          screen_hint: 'signup',
                        },
                      });
                    }}
                    style={{ fontWeight: 'bold', cursor: 'pointer', color: 'blue' }}
                  >
                    signup for more
                  </a>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StartChatInput;
