import React, {useEffect, useRef, useState} from 'react';
import {Dropdown} from 'react-bootstrap';
import cn from 'classnames';
import {apps_config_list} from '../appsConfig';

export const AppSelect = ({ isOpen, setIsOpen, setActiveProduct, clearInput }) => {
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  const [inputValue, setInputValue] = useState('');
  const [filteredProductList, setFilteredProductList] = useState([]);
  const [focusedAppIndex, setFocusedAppIndex] = useState(0);

  useEffect(() => {
    const visibleApps = apps_config_list.filter(app => !app.invisible);
    setFilteredProductList(visibleApps);
  }, []);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => inputRef.current.focus(), 200);
    }
  }, [isOpen]);

  const handleProductSelect = (key) => {
    setActiveProduct(key);
    setIsOpen(false);
    setInputValue('');
  };

  const handleKeyPress = (e) => {
    const key = e.key;
    const productsCount = filteredProductList.length - 1;

    if (key === 'Escape') {
      setIsOpen(false);
      clearInput();
    }

    if (key === 'Enter') {
      setIsOpen(false);
      setInputValue('');
      setActiveProduct(filteredProductList[focusedAppIndex]?.id);
    }

    if (key === 'ArrowUp') {
      setFocusedAppIndex((productIndex) => {
        const index = productIndex <= 0 ? productsCount : productIndex - 1;
        dropdownRef.current.scrollTo(0, index * 20);
        return index;
      });
    }

    if (key === 'ArrowDown') {
      setFocusedAppIndex((productIndex) => {
        const index = productIndex >= productsCount ? 0 : productIndex + 1;
        dropdownRef.current.scrollTo(0, index * 20);
        return index;
      });
    }
  };

  const handleInputOnChange = (e) => {
    const newMessage = e.target.value.replace('@', '');
    const filteredItems = apps_config_list.filter(app =>
        app.displayName.toLowerCase().includes(newMessage.toLowerCase()) && !app.invisible
    );

    setInputValue(newMessage);
    setFilteredProductList(filteredItems);
  };

  return (
      <div className="w-100 pb-2 dropup">
        {isOpen && (
            <Dropdown
                show={isOpen}
                onSelect={handleProductSelect}
                drop="up"
            >
              <Dropdown.Toggle as="div" className="w-100">
                <input
                    ref={inputRef}
                    value={inputValue}
                    className="w-100 dropdown-input"
                    onChange={handleInputOnChange}
                    onKeyDown={handleKeyPress}
                    id='appSelectInput'
                />
              </Dropdown.Toggle>
              <Dropdown.Menu className="chat-bots-container" ref={dropdownRef}>
                {filteredProductList.length > 0 ? (
                    filteredProductList.map((app, index) => (
                        <Dropdown.Item
                            key={index}
                            eventKey={app.id}
                            className={cn("", {
                              "selected-chat-bot": focusedAppIndex === index,
                            })}
                            onMouseEnter={() => setFocusedAppIndex(index)}
                        >
                          {app.displayName}
                        </Dropdown.Item>
                    ))
                ) : (
                    <Dropdown.Item disabled>No matching chats</Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
        )}
      </div>
  );
};
