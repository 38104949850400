import { useState, useEffect } from 'react'

function useLocalStorageBoolean(key, initialValue) {
	const [value, setValue] = useState(() => {
		const storedValue = localStorage.getItem(key)
		return storedValue !== null ? JSON.parse(storedValue) : initialValue
	})

	useEffect(() => {
		localStorage.setItem(key, JSON.stringify(value))
	}, [key, value])

	return [value, setValue]
}

function useLocalStorageInteger(key, initialValue) {
	const [value, setValue] = useState(() => {
		const storedValue = localStorage.getItem(key)
		return storedValue !== null ? parseInt(storedValue, 10) : initialValue
	})

	useEffect(() => {
		localStorage.setItem(key, value.toString())
	}, [key, value])

	return [value, setValue]
}

export { useLocalStorageBoolean, useLocalStorageInteger }
