import React, { useState } from 'react';
import { Dropdown, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const NavbarDropdown = ({ app, activeProduct, resetSelectedChat }) => {
    const navigate = useNavigate();
    const [showDropdown, setShowDropdown] = useState(false);

    const handleToggleDropdown = (isOpen) => {
        setShowDropdown(isOpen);
    };

    const handleSelectProduct = (key) => {
        navigate(`/${key}`);
        resetSelectedChat();
        handleToggleDropdown(false);
    };

    const visibleChats = Object.entries(app).filter(([_key, config]) => !config.invisible);

    return (
        <div className="nav-logo btn-group">
            <Link to="/home" className="link-transparent btn btn-link nav-link" style={{ backgroundColor: 'transparent', textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
                <span className="nL-Okami">Ō<span className='k-letter'>k</span>ami</span>
            </Link>
                <div className="header-dropdown">
                    <Button
                        type="button"
                        className="btn btn-link nav-link dropdown-toggle dropdown-toggle-split"
                        onClick={() => handleToggleDropdown(!showDropdown)}
                        aria-haspopup="true"
                        aria-expanded={showDropdown}
                        style={{ backgroundColor: 'transparent', border: 'none' }}
                    >
                        <span>- </span><span className="nL-CM">{activeProduct || 'Select a Product'}</span>
                        <span className="sr-only"></span>
                    </Button>
                </div>
            <Dropdown show={showDropdown} onToggle={handleToggleDropdown}>
                <Dropdown.Menu>
                    {visibleChats.length > 0 ? (
                        visibleChats.map(([key, config]) => (
                            <Dropdown.Item
                                key={key}
                                onClick={() => {
                                    navigate(`/${key}`);
                                    handleSelectProduct(key);
                                }}
                                className={activeProduct === config.displayName ? 'bold-black-link active' : 'bold-black-link'}
                            >
                                {config.displayName} <i className="fa-solid fa-xmark"></i>
                            </Dropdown.Item>
                        ))
                    ) : (
                        <Dropdown.Item disabled>No available items</Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default NavbarDropdown;
