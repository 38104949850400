import React, { useLayoutEffect, useRef, useState } from 'react';
import Modal from '../components/Modals/VideoModal';
import {codify, formatNote} from './utils';

export default function MarkdownRenderer({ markdownText, setHasCode, getDataRefByUrl}) {
  const [htmlContent, setHtmlContent] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [videoIdAndTime, setVideoIdAndTime] = useState({})
  const codeRef = useRef(null);

  useLayoutEffect(() => {
    const newHtmlContent = codify(markdownText, getDataRefByUrl);
    setHtmlContent(newHtmlContent);
    if (setHasCode) {
      setHasCode(/<pre.*?>.*?<\/pre>/s.test(newHtmlContent));
    }
  }, [markdownText, setHasCode]);

  useLayoutEffect(() => {
    if (codeRef.current) {
      codeRef.current.querySelectorAll('pre code').forEach((preElement) => {
        if (!preElement.closest('.copy-replay-btn')) {
          const div = document.createElement('div');
          const icon = document.createElement('i');
          const span = document.createElement('span');
          div.style.cssText = `display: flex;
                                         width: 100%;
                                         justify-content: space-between;
                                         position: sticky;
                                         top: 0;
                                         left: 0;
                                         right: 0;
                                         padding: 5px 10px 5px 10px;
                                         border: 0px solid #ccc !important;
                                         background-color: gray;
                                         color: white;
                                         border-radius: 0px 0px 5px 5px;
                                         z-index: 10;
                                         cursor: pointer;
                                         margin-bottom: 15px;`;

          span.style.cssText = `font-size: 12px;`
          span.innerHTML = 'Copy code';
          icon.className = 'bi bi-files';
          div.appendChild(span);
          div.appendChild(icon);

          div.onclick = () => {
            span.innerHTML = '';

            navigator.clipboard.writeText(preElement.textContent).then(() => {
              icon.className = 'bi bi-check2';
              span.innerHTML = 'Code copied';
              setTimeout(() => {
                icon.className = 'bi bi-files';
                span.innerHTML = 'Copy code';
              }, 2000);
            });
          };

          preElement.prepend(div);
        }
      });

      codeRef.current.querySelectorAll('a').forEach((link) => {
        const href = link.getAttribute('href');
        if (href && href.includes('https://www.youtube.com')) {
          link.onclick = (event) => {
            event.preventDefault();
            const videoId = new URLSearchParams(new URL(href).search).get('v');
            const startTime = new URLSearchParams(new URL(href).search).get('start');
            setVideoIdAndTime({"videoId": videoId, "startTime": startTime});
            setIsOpen(true);
          };
        }
      });
    }
  }, [htmlContent]);




  return (
    <div>
      <div className="markdown-content" ref={codeRef} dangerouslySetInnerHTML={{ __html: formatNote(htmlContent) }} />
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} videoIdAndTime={videoIdAndTime} />
    </div>
  );
}
