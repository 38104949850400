import classNames from 'classnames';
import React, { useContext, useEffect, useRef } from 'react';
import { Nav, OverlayTrigger, Popover } from 'react-bootstrap';
import { ChatHistoryContext } from '../HistoryContext';
import { useNavigate, useLocation } from 'react-router-dom';
import LogOutButton from "../LogoutButton";
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';

const popover = (title) => (
    <Popover id="popover-basic">
        <Popover.Body>
            {title}
        </Popover.Body>
    </Popover>
);

const SideBar = ({ isOpen, closeSidebar, toggleSidebar, isAuthenticated, appEnabled, user }) => {
    const { history, selectedChat, setSelectedChat } = useContext(ChatHistoryContext);
    const navigate = useNavigate();
    const location = useLocation();
    const isMobile = window.innerWidth <= 768;
    const prevChatRef = useRef(selectedChat);

    useEffect(() => {
        const prevChat = prevChatRef.current;

        if (prevChat && prevChat.chatId !== selectedChat?.chatId) {
            document.querySelectorAll('.sidebar-history-item-selected').forEach(element => {
                element.classList.remove('sidebar-history-item-selected');
            });
        }

        if (selectedChat) {
            const currentItem = document.querySelector(`.sidebar-history-item[data-chat-id="${selectedChat.chatId}"]`);
            if (currentItem) {
                currentItem.classList.add('sidebar-history-item-selected');
            }
        }

        prevChatRef.current = selectedChat;
    }, [location.pathname, selectedChat]);

    return (
        <>
            {isOpen && isMobile && (
                <div
                    onClick={closeSidebar}
                    className="position-fixed w-100 h-100 top-0 left-0 opacity-0"
                    style={{ zIndex: 300 }}
                />
            )}
            <div className={classNames('mh-100 h-100 sidebar', { 'is-open': isOpen })}>
                <button className="sidebar-toggle" onClick={toggleSidebar}>
                    {isOpen ? <FaArrowLeft /> : <FaArrowRight />}
                </button>
                <div className="sidebar-history-wrapper">
                    <Nav className="flex-column pt-1 px-2">
                        <p>History</p>
                    </Nav>
                    <Nav className="sidebar-history-container">
                        {history.map((item) => (
                            <OverlayTrigger
                                key={`overlay-${item.chatId}`}
                                rootClose={true}
                                overlay={popover(item?.title)}
                                placement="right"
                            >
                                <Nav.Item as="div">
                                    <Nav.Link
                                        className={classNames('sidebar-history-item', {
                                            'sidebar-history-item-selected': selectedChat?.chatId === item.chatId,
                                        })}
                                        data-chat-id={item.chatId}
                                        onClick={() => {
                                            setSelectedChat(item);
                                            navigate(item?.app ? `/${item.app}` : `/aws`);
                                        }}
                                        style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '200px' }}
                                    >
                                        {item.title}
                                    </Nav.Link>
                                </Nav.Item>
                            </OverlayTrigger>
                        ))}
                    </Nav>
                </div>
                <div className="sidebar-bottom">
                    <div className="sidebar-divider"></div>
                    {isAuthenticated && (
                        <div className="auth-section">
                            <p>Signed in as: {user.name}</p>
                            {appEnabled && <LogOutButton />}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default SideBar;
