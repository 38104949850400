import React, { useState, useEffect } from 'react';
import { ComputerAvatar } from '../Avatars';
import { Button } from 'react-bootstrap';
import MarkdownRenderer from '../../scripts/helpers';
import Dots from '../Dots';

const BotReplyMessage = ({ message, isWaiting, getDataRefByUrl}) => {
    const [copied, setCopied] = useState(false);
    const [hasCode, setHasCode] = useState(false);

    const handleCopyMessage = async () => {
        if (typeof message === 'string') {
            await navigator.clipboard.writeText(message);
            setCopied(true);
        }
    };

    const renderContent = () => {
        if (typeof message === 'string') {
            return message !== '' ? <MarkdownRenderer markdownText={message} setHasCode={setHasCode} getDataRefByUrl={getDataRefByUrl} /> : <Dots isWaitingForResponse={isWaiting} />;
        } else {
            return message;
        }
    };

    return (
        <div className="bot-message">
            <ComputerAvatar />
            <div className="response-content">
                {renderContent()}
            </div>
            {/* <div>
                <Button className="copy-button" disabled={hasCode} onClick={handleCopyMessage}>
                    {copied ? <i className="bi bi-check2"></i> : <i className="bi bi-files"></i>}
                </Button>
            </div> */}
        </div>
    );
};

export default BotReplyMessage;
