import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import ChatResponse from './ChatResponse';
import { ChatHistoryContext } from './HistoryContext';
import socket from '../api/socket';

const PromptCompletionPair = ({
  user,
  isWaitingForResponse,
  setIsWaitingForResponse,
  setIsStopDisabled,
}) => {
  const [responseMessage, setResponseMessage] = useState({ message: '' });
  const [isFinished, setIsFinished] = useState(false);
  const { updateChat, selectedChat } = useContext(ChatHistoryContext);
  const [newDataRefs, setNewDataRefs] = useState([]); // Fix here
  const replyInputRef = useRef(null);

  const handleMessage = useCallback(
    (receivedMsg) => {
      if (receivedMsg.message !== '') {
        setResponseMessage({ message: receivedMsg.message });
      }

      if (receivedMsg.finished === true) {
        setIsWaitingForResponse(false);
        setIsStopDisabled(false);
        setIsFinished(true);
        setNewDataRefs(receivedMsg.data_refs)
      }
    },
    [setIsWaitingForResponse],
  );

  useEffect(() => {
    if (isFinished) {
      let mergedDataRefs = [];

      if (newDataRefs?.length > 0) {
        mergedDataRefs = [...newDataRefs];
      }

      if (selectedChat.data_refs?.length > 0) {
        mergedDataRefs = mergedDataRefs.concat(selectedChat.data_refs);
      }

      updateChat(selectedChat.chatId, { role: 'assistant', content: responseMessage.message }, mergedDataRefs);

      setTimeout(() => {
        replyInputRef.current?.focus();
      }, 300);

      setResponseMessage({ message: '' });
      setIsFinished(false);
    }
  }, [isFinished, responseMessage, updateChat, selectedChat, newDataRefs, replyInputRef]);

  useEffect(() => {
    socket.on(selectedChat.chatId, handleMessage);
    return () => {
      socket.off(selectedChat.chatId, handleMessage);
    };
  }, [selectedChat.chatId, handleMessage]);

    return (
        <div className="promptCompletionPair p-0">
            <div className="rounded message-container received-container position-relative">
                <ChatResponse
                    user={user}
                    responseMessage={responseMessage}
                    isWaitingForResponse={isWaitingForResponse}
                />
            </div>
        </div>
    );
};

export default PromptCompletionPair;
