import React from "react";
import showdown from "showdown";
import hljs from "highlight.js";

export const getFaviconUrl = (url) => {
    try {
        const { hostname } = new URL(url);
        return `https://www.google.com/s2/favicons?domain_url=${hostname}`;
    } catch (error) {
        return null;
    }
};

export function createYouTubeLink(url, startTime) {
    function parseTime(timeStr) {
        if (timeStr === null) {
            return null;
        }
        timeStr = timeStr.trim(); // Trim whitespace
        const parts = timeStr.split(':');
        if (parts.length !== 3) {
            return null;
        }
        try {
            const hours = parseInt(parts[0], 10);
            const minutes = parseInt(parts[1], 10);
            const [seconds, milliseconds] = parts[2].split(',').map(Number);
            if (isNaN(hours) || isNaN(minutes) || isNaN(seconds) || isNaN(milliseconds)) {
                throw new Error("Invalid number");
            }
            const totalSeconds = hours * 3600 + minutes * 60 + seconds + milliseconds / 1000;
            return totalSeconds;
        } catch (error) {
            return null;
        }
    }

    const startSeconds = parseTime(startTime);

    if (startSeconds === null) {
        return url; // Return the original URL if timestamps are invalid
    }

    const newUrl = `${url}&start=${Math.floor(startSeconds)}`;
    return newUrl;
}

export function createHighlightedLink(url, quote) {
    if (quote === null || quote === undefined) {
        return url;
    }
    const encodedText = encodeURIComponent(quote);
    return `${url}#:~:text=${encodedText}`;
}

export function urlify(elements) {
    if (!elements) return;

    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const processText = (text) => {
        return text.split(urlRegex).map((part, index) => {
            if (part.match(urlRegex)) {
                return (
                    <a key={index} target="_blank" href={part} rel="noreferrer">
                        {part}
                    </a>
                );
            }
            return part;
        });
    };

    return React.Children.map(elements, (child) => {
        if (typeof child === 'string') {
            return processText(child);
        }
        return child;
    });
}

export function codify(text, getDataRefByUrl) {
    if (!text.trim()) return '';

    const converter = new showdown.Converter({
        openLinksInNewWindow: true,
        simpleLineBreaks: true,
        ghCompatibleHeaderId: true,
        tables: true,
        simplifiedAutoLink: true,
        smoothLivePreview: true,
        smartIndentationFix: true,
        ghCodeBlocks: true
    });

    converter.setFlavor('github');
    const html = converter.makeHtml(text);

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    tempDiv.querySelectorAll('h1, h2, h3, h4, h5, h6').forEach((title) => {
        title.classList.add('indent-title');
    });

    tempDiv.querySelectorAll('pre code').forEach((block) => {
        hljs.highlightElement(block);
        block.style.display = 'block';
        block.style.width = 'auto';
        block.style.position = 'relative';
        block.style.overflow = 'hidden';
        block.style.overflowX = 'auto';
        block.style.paddingBottom = '1rem';
    });

    tempDiv.querySelectorAll('a').forEach((link) => {
        const href = link.getAttribute('href');
        const faviconUrl = getFaviconUrl(href);

        const dataRef = getDataRefByUrl(href);

        if (dataRef) {
            // if link is youtube
            if (href.includes('youtube.com')) {
                const { start_time_stamp } = dataRef;
                const newUrl = createYouTubeLink(href, start_time_stamp);
                link.setAttribute('href', newUrl);
            } else if (dataRef.quote !== null && dataRef.quote !== undefined) {
                const highlightedLink = createHighlightedLink(href, dataRef.quote);
                link.setAttribute('href', highlightedLink);
            }
        }

        if (faviconUrl) {
            const faviconImg = document.createElement('img');
            faviconImg.src = faviconUrl;
            faviconImg.alt = 'Favicon';
            faviconImg.style.width = '16px';
            faviconImg.style.height = '16px';
            faviconImg.style.marginRight = '4px';
            link.prepend(faviconImg);
        }
    });

    return tempDiv.innerHTML;
}

export const formatNote = (content) => {
    return content.replace(/(^|\W)(Note)(\W|$)/g, '$1<strong>* $2</strong>$3');
};
