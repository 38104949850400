import React from 'react';
import { PersonAvatar } from '../Avatars';
import MarkdownRenderer from '../../scripts/helpers';

const UserReplyMessage = ({ message, userImage }) => {
    return (
        <div className="parent-container">
            <div className="user-message-container">
                <PersonAvatar src={userImage}/>
                <div className="user-message-content">
                    {message}
                </div>
            </div>
        </div>

    );
};

export default UserReplyMessage;
