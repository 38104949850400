import React from 'react';
import closeImg from '../../images/close.svg';

function Modal({ isOpen, onClose, videoIdAndTime, processedTitle }) {
  if (!isOpen) return null;

  const handleContainerClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  const videoSrc = `https://www.youtube.com/embed/${videoIdAndTime.videoId}?autoplay=1&start=${videoIdAndTime.startTime}`;

  return (
    <div className="modal-video-container" onClick={handleContainerClick}>
      <div className="modal-content">
        <div className="modal-video-x-btn">
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '6px 10px 5px 20px',
            width: '100%',
            position: 'absolute',
            top: '0px',
            right: '0px',
            zIndex: '1001'
          }} onClick={onClose}>
            <div className="modal-title">{processedTitle}</div>
            <img src={closeImg} alt="close"/>
          </div>
        </div>
        <iframe className="modal-iframe" src={videoSrc} allowFullScreen allow="autoplay; fullscreen" title={videoIdAndTime.videoId}></iframe>
      </div>
    </div>
  );
}

export default Modal;
