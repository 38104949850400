import { Modal, Button } from 'react-bootstrap'
import cn from 'classnames'

function AccountSettingsModal ({ show, hide, user, messagesLeft }) {
	return user && (
		<Modal
			show={show}
			onHide={() => hide(false)}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			className="modal-settings"
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Settings
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="modal-settings__body">
				<div className="modal-settings__left-bar">
					<div className={cn("modal-settings__left-bar-item", {
						"modal-settings__left-bar-item_active": true
					})}>
						<i className="bi bi-gear"></i>General
					</div>
					<div className={cn("modal-settings__left-bar-item", {
						
					})}>
						<i className="bi bi-bar-chart"></i>Data controls
					</div>
				</div>
				<div className="d-flex flex-column flex-grow-1">
					<div className="d-flex gap-3 p-2 border-bottom">
						<img 
							alt="avatar" 
							src={user?.picture}
							className="img-fluid"
							style={{ width: "50px", height: "50px", borderRadius: "5px" }}
						/>
						<div className="flex-grow-1">
							<h5 style={{ color: "#2b2a2a", fontWeight: "bold" }}>{user?.nickname}</h5>
							<div style={{ color: "#4a4949" }}>{user?.email}</div>
						</div>
					</div>
					<div className="d-flex justify-content-between p-2 border-bottom">
						<span className="small mb-1">Messages left</span>
						{messagesLeft}
					</div>
					<div className="d-flex justify-content-between p-2 border-bottom">
						<Button variant="outline-primary" className="w-100">License (100 messages free)</Button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
}

export default AccountSettingsModal
