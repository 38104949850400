import { useAuth0 } from '@auth0/auth0-react';
import Button from 'react-bootstrap/Button';

const LogOutButton = () => {
	const { logout, isAuthenticated } = useAuth0();

	const handleLogout = () => {
		localStorage.clear();
		logout();
	};

	return (
		isAuthenticated && (
			<Button variant="link" onClick={handleLogout}>
				<i className="bi bi-box-arrow-right"></i>
			</Button>
		)
	);
};

export default LogOutButton;
