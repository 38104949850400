const apps_config_map = {
	kubernetes: {
		displayName: 'Kubernetes',
		company: 'The Linux Foundation',
	},
	datadog: {
		displayName: 'Datadog',
		company: 'Datadog, Inc',
	},
	jenkins: {
		displayName: 'Jenkins',
		company: 'The Continuous Delivery Foundation (CDF)',
	},
	'ctrlm': {
		displayName: 'Control-M',
		company: 'BMC Software',
	},
	cloudflare: {
		displayName: 'Cloudflare',
		company: 'Cloudflare, Inc',
	},
	gcp: {
        displayName: 'Google Cloud Platform',
        company: 'Google LLC',
	},
	cloudinary: {
		displayName: 'Cloudinary',
		company: 'Cloudinary, Inc',
	},
	amazon: {
		company: 'Amazon.com',
		displayName: 'AWS',
	},
}

export const apps_config_list = [
	{
		company: 'Amazon.com',
		displayName: 'AWS',
		id: 'amazon',
	},
	{
		displayName: 'Control-M',
		company: 'BMC Software',
		id: 'ctrlm',
	},
	{
		displayName: 'Kubernetes',
		company: 'The Linux Foundation',
		id: 'kubernetes',
	},
	{
		displayName: 'Datadog',
		company: 'Datadog, Inc',
		id: 'datadog',
	},
	{
		displayName: 'Jenkins',
		company: 'The Continuous Delivery Foundation (CDF)',
		id: 'jenkins',
	},
	{
		displayName: 'Cloudflare',
		company: 'Cloudflare',
		id: 'cloudflare',
	},
	{
		displayName: 'Google Cloud Platform',
		company: 'Google LLC',
		id: 'gcp',
	},
	{
		displayName: 'Cloudinary',
		company: 'Cloudinary, Inc',
		id: 'cloudinary',
	},
]

export default apps_config_map
